import React, { useCallback, useEffect, useState } from "react";
import { ChannelAssemblyRecord, ChannelLensRecord } from "../../../../../../database/Products";
import { Autocomplete, Box, Button, CircularProgress, MenuItem, useTheme } from "@mui/material";
import { SelectField } from "../../../components/TextField";
import StyledTextField from "../../../../../../components/StyledTextField";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { getLensOptions } from "./data";

type NewOverrideProps = {
  allChannelAssemblies: Pick<ChannelAssemblyRecord, 'id' | 'name'>[];
  onSubmit: (assemblyId: number, lensId: number | null, compatible: boolean) => void;
  onCancel: () =>  void;
}

const NewOverride = ({ allChannelAssemblies, onSubmit, onCancel }: NewOverrideProps) => {
  const colors = useTheme().colors;
  const [assemblySearch, setAssemblySearch] = useState<string>('');
  const [selectedAssembly, setSelectedAssembly] = useState<number | null>(null);
  const [selectedLens, setSelectedLens] = useState<number | null>(null);
  const [selectedCompatible, setSelectedCompatible] = useState<boolean>(true);
  const [queryingLensOptions, setQueryingLensOptions] = useState<boolean>(false);
  const [lensOptions, setLensOptions] = useState<Pick<ChannelLensRecord, 'id' | 'name'>[]>([]);

  const queryLensOptions = useCallback(async (assemblyId: number) => {
    setQueryingLensOptions(true);
    getLensOptions(assemblyId).then((data) => {
      setLensOptions(data);
      setQueryingLensOptions(false);
    });
  }, []);

  useEffect(() => {
    if(allChannelAssemblies.length > 0) setSelectedAssembly(allChannelAssemblies[0].id);
  }, []);

  const handleAssemblyChange = (assemblyName: string | null) => {
    const assemblyId = allChannelAssemblies.find(assembly => assembly.name === assemblyName)?.id ?? null;
    if(assemblyId === selectedAssembly) return;
    setLensOptions([]);
    if(assemblyId !== null) queryLensOptions(assemblyId);
    setSelectedAssembly(assemblyId);
  };

  const handleLensChange = (lensValue: string | number) => {
    const lensId = lensValue === 'all' ? null : parseInt(lensValue.toString());
    if(lensId === selectedLens) return;
    setSelectedLens(lensId);
  };

  const handleCompatibleChange = (value: string | number) => {
    const compatible = value === 'yes';
    setSelectedCompatible(compatible);
  };

  const handleAddButton = () => {
    if(!selectedAssembly) return;
    onSubmit(selectedAssembly, selectedLens, selectedCompatible);
  };

  return (    
    <Box my={2} maxWidth='750px' display='flex' flexDirection='row' gap={2} alignItems='center'>
      <Autocomplete
        disablePortal
        id="assemblyLookup"
        options={allChannelAssemblies.map((assembly) => assembly.name)}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label="Channel Assembly"
          />
        )}
        value={selectedAssembly ? allChannelAssemblies.find(v => selectedAssembly === v.id)?.name : null}
        onChange={(event, newValue) => handleAssemblyChange(newValue)}
        inputValue={assemblySearch}
        onInputChange={(event, newValue) => setAssemblySearch(newValue)}
        size="small"
        sx={{
          minWidth: '225px',
          "& label.Mui-focused": {
            color: colors.blueAccent[500],
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: colors.blueAccent[500],
            },
          },
        }}
      />
      {
        queryingLensOptions ?
        <CircularProgress />
        :
        <SelectField 
          label='Lens'
          value={selectedLens ?? 'all'}
          onChange={(e) => handleLensChange(e.target.value)}
          updated={false}
          fullWidth
        >
          <MenuItem value={'all'}>All</MenuItem>
          {
            lensOptions.map(lens => (
              <MenuItem key={lens.id} value={lens.id}>{lens.name}</MenuItem>
            ))
          }
        </SelectField>
      }
      <SelectField 
        label='Compatible?'
        value={selectedCompatible ? 'yes' : 'no'}
        onChange={(e) => handleCompatibleChange(e.target.value)}
        updated={false}
        fullWidth
      >
        <MenuItem value='yes'>Yes</MenuItem>
        <MenuItem value='no'>No</MenuItem>
      </SelectField>
      <Box>
        <Button 
          color='secondary' 
          variant='contained' 
          startIcon={<AddOutlinedIcon />}
          onClick={() => handleAddButton()}
          disabled={!selectedAssembly}
        >
          Add
        </Button>
      </Box>
      <Box>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default NewOverride;