import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Chip, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { ChannelAssemblyRecord, CompatibilityOverride, getCompatibilityOverrides } from "../../../../../../database/Products";
import { deleteOverride, getAllChannelAssemblies, insertOverride } from "./data";
import NewOverride from "./NewOverride";

type CompatibilityOverridesProps = {
  ledTapeSlug: string;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const CompatibilityOverrides = ({ ledTapeSlug, displayAlert }: CompatibilityOverridesProps) => {
  const colors = useTheme().colors;
  const [loading, setLoading] = useState<boolean>(true);
  const [overrides, setOverrides] = useState<CompatibilityOverride[]>([]);
  const [newOverride, setNewOverride] = useState<boolean>(false);
  const [allChannelAssemblies, setAllChannelAssemblies] = useState<Pick<ChannelAssemblyRecord, 'id' | 'name'>[]>([]);

  const queryOverridesData = useCallback(async () => {
    const [overridesData, allChannelAssembliesData] = await Promise.all([
      getCompatibilityOverrides(ledTapeSlug),
      getAllChannelAssemblies()
    ]);
    setOverrides(overridesData);
    setAllChannelAssemblies(allChannelAssembliesData);
    setLoading(false);
  }, []);

  useEffect(() => {
    queryOverridesData();
  }, []);

  const handleSubmitNewOverride = (assemblyId: number, lensId: number | null, compatible: boolean) => {
    insertOverride(ledTapeSlug, assemblyId, lensId, compatible)
    .then((status) => {
      if(status >= 200 && status < 300){
        displayAlert('Successfully added compatibility override.', 'success');
        queryOverridesData();
      }else{
        displayAlert('Error added compatibility override.', 'error');
      }
    });
    setNewOverride(false);
  };

  const handleRemoveExistingOverride = (overrideId: number) => {
    deleteOverride(overrideId)
    .then((status) => {
      if(status >= 200 && status < 300){
        displayAlert('Successfully removed override.', 'success');
        queryOverridesData();
      }else{
        displayAlert('Error removing override.', 'error');
      }
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography mb={2}>Configurations here will override the default compatibility logic which simply compares endcap dimensions to the internal dimensions of channel.</Typography>
        {
          loading &&
          <CircularProgress />
        }
        {
          !loading && overrides.length === 0 &&
          <Typography color={colors.grey[500]}>No compatibility overrides.</Typography>
        }
        {
          !loading &&
          <TableContainer sx={{ maxWidth: '750px'}}>
            <Table>
              <TableHead sx={{ background: colors.primary[400]}}>
                <TableRow>
                  <TableCell><Typography fontWeight='bold'>Channel Assembly</Typography></TableCell>
                  <TableCell><Typography fontWeight='bold'>Lens</Typography></TableCell>
                  <TableCell><Typography fontWeight='bold'>Compatible?</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  overrides.map(override => (
                    <TableRow key={override.id}>
                      <TableCell><Typography>{override.assembly?.name ?? ''}</Typography></TableCell>
                      <TableCell><Typography>{override.lens?.name ?? 'All'}</Typography></TableCell>
                      <TableCell>
                        {
                          override.compatible ?
                          <Chip label="Yes" color="success" />
                          : <Chip label="No" color="error" />
                        }
                      </TableCell>
                      <TableCell>
                        <Button color="error" onClick={() => handleRemoveExistingOverride(override.id)}>Remove</Button>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          newOverride &&
          <NewOverride
            allChannelAssemblies={allChannelAssemblies}
            onSubmit={handleSubmitNewOverride}
            onCancel={() => setNewOverride(false)}
          />
        }
        <Box mt={2}>
          <Button onClick={() => setNewOverride(true)} disabled={!!newOverride}>
            Add Override
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CompatibilityOverrides;