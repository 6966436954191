import { ChannelAssemblyRecord, ChannelLensRecord, CompatibilityOverrideRecord } from "../../../../../../database/Products";
import { supabase_products } from "../../../../../../database/supabaseClient";

export const getAllChannelAssemblies = async (): Promise<Pick<ChannelAssemblyRecord, 'id' | 'name'>[]> => {
  const query = supabase_products.from('channel_assemblies').select('id, name').order('name', { ascending: true });
  const { data, error } = await query;
  if(error) console.error(error);
  if(!data) return [];
  return data;
}

export const getLensOptions = async(assemblyId: number): Promise<Pick<ChannelLensRecord, 'id' | 'name'>[]> => {
  const query = supabase_products.from('channel_lenses').select('id, name');
  query.eq('channel_assembly_id', assemblyId);
  query;
  const { data, error } = await query;
  if(error) console.error(error);
  if(!data) return [];
  return data;
}

export const insertOverride = async(ledTapeSlug: string, assemblyId: number, lensId: number | null, compatible: boolean): Promise<number> => {
  const newRecord: Pick<CompatibilityOverrideRecord, 'led_tape_slug' | 'assembly_id' | 'lens_id' | 'compatible'> = {
    led_tape_slug: ledTapeSlug,
    assembly_id: assemblyId,
    lens_id: lensId,
    compatible: compatible
  }
  const query = supabase_products.from('compatibility_overrides').insert(newRecord);
  const { error, status } = await query;
  if(error) console.error(error);
  return status;
}

export const deleteOverride = async(overrideId: number) => {
  const query = supabase_products.from('compatibility_overrides').delete().eq('id', overrideId);
  const { error, status } = await query;
  if(error) console.error(error);
  return status;
}