import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Divider, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LedTapeRecord } from '../../../../../database/Products';
import { supabase_products } from '../../../../../database/supabaseClient';
import BasicLedTapeDetails from './BasicLedTapeDetails';
import { default as TabPanel } from "../../../../../components/StyledTabPanel";
import OptionsConfig from './OptionsConfig/OptionsConfig';
import SpecsConfig from './SpecsConfig/SpecsConfig';
import SkuFormulaConfig from './SkuFormulaConfig';
import { AlertUi } from '../../types';
import CompatibilityOverrides from './CompatibilityOverrides/CompatibilityOverrides';

const LedTapeConfig = () => {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();

  const [ledTape, setLedTape] = useState<LedTapeRecord | null>(null);
  const [queryError, setQueryError] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});

  const queryData = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('led_tape').select('*').eq('slug', slug).single();
    if(error) console.error(error);
    if(!data){
      setQueryError('Unable to query data.');
      return;
    }
    setLedTape(data);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCloseAlert = () => {
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplyAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  const handleReloadData = async () => {
    queryData();
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap="15px" mb={2}>
        <Button 
          startIcon={<ArrowBackIosNewOutlinedIcon color="inherit" />}
          onClick={() => navigate(`../?type=led-tape`)}
        >
          LED Tape List
        </Button>
        {ledTape && <Typography variant="h3">{ledTape.name}</Typography>}
      </Box>
      { 
        !ledTape && queryError === '' &&
        <CircularProgress />
      }
      {
        queryError !== '' &&
        <Typography color='red'>{queryError}</Typography>
      }
      {
        ledTape &&
        <>
        <Tabs
          value={tabValue}
          onChange={changeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Details" value={0} />
          <Tab label="Options" value={1} />
          <Tab label="Specs" value={2} />
          <Tab label="SKU Formula" value={3} />
          <Tab label="Compatibility Override" value={4} />
        </Tabs>
        <Divider />
        <TabPanel value={tabValue} index={0}>
          <BasicLedTapeDetails 
            record={ledTape}
            displayAlert={handleDisplyAlert} 
            reloadData={handleReloadData} 
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <OptionsConfig 
            record={ledTape} 
            displayAlert={handleDisplyAlert} 
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <SpecsConfig 
            record={ledTape} 
            //displayAlert={handleDisplyAlert}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <SkuFormulaConfig 
            record={ledTape} 
            displayAlert={handleDisplyAlert} 
            reloadData={handleReloadData} 
          />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <CompatibilityOverrides 
            ledTapeSlug={slug ?? ''} 
            displayAlert={handleDisplyAlert} 
          />
        </TabPanel>
        </>
      }
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LedTapeConfig;