import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import SettingsIcon from "@mui/icons-material/Settings";
import StyledNumberInput from "../../../components/StyledNumberInput";
import ServiceComponent from "./ServiceComponent";
import BaseComboComponent from "./BaseComboComponent";
import CurrencyFormatter from "../../../global_functions/CurrencyFormatter";
import Config from "./Config/Config";
//import { DatabaseContext } from "./DatabaseManager";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { ServiceComponentObj, getRecords, getServiceComponentRecords } from "./data";
import {
  BaseComboRecord,
  ControllerTypeRecord,
  ProjectTypeRecord,
} from "../../../database/AppsDescGenToolTypes";
import { hasPermission } from "../../../database/Permissions";

type Output = { 
  price: number; 
  desc: string;
}

const PERCENT_PER_UNIVERSE = 5;

const ProgrammingDescPriceTool = () => {
  const colors = useTheme().colors;
  const profile = useSelector((state: RootState) => state.profile);
  const configGranted = hasPermission(profile, "TOOL_PROG_PRICE_DESC_CONFIG");

  const [controllerTypes, setControllerTypes] = useState<ControllerTypeRecord[]>([]);
  const [projectTypes, setProjectTypes] = useState<ProjectTypeRecord[]>([]);
  const [baseCombos, setBaseCombos] = useState<BaseComboRecord[]>([]);
  const [serviceComponents, setServiceComponents] = useState<ServiceComponentObj[]>([]);

  const [selProjType, setSelProjType] = useState<ProjectTypeRecord | null>(projectTypes[0] ?? null);
  const [selControllerType, setSelControllerType] = useState<ControllerTypeRecord | null>(controllerTypes[0] ?? null);
  const [uniMultiplier, setUniMultiplier] = useState<number>(1);
  const [outputs, setOutputs] = useState<Output[]>([]);
  const [copiedDesc, setCopiedDesc] = useState(false);
  const [configView, setConfigView] = useState(false);

  const reloadData = useCallback(async () => {
    try {
      const [ controllerTypeRecords, projectTypeRecords, baseComboRecords, serviceComponentObjs ] = await Promise.all([
        getRecords<ControllerTypeRecord> ("controller_types", false, "display_index"),
        getRecords<ProjectTypeRecord> ("project_types", false, "display_index"),
        getRecords<BaseComboRecord> ("base_combos", false, "project_type_id"),
        getServiceComponentRecords()
      ]);
      setControllerTypes(controllerTypeRecords);
      setProjectTypes(projectTypeRecords);
      setBaseCombos(baseComboRecords);
      setServiceComponents(serviceComponentObjs);

      if(projectTypeRecords !== null && projectTypeRecords.length > 0)
        setSelProjType(projectTypeRecords.filter(pt => pt.active)[0]);
      if(controllerTypeRecords !== null && controllerTypeRecords.length > 0) 
        setSelControllerType(controllerTypeRecords.filter(ct => ct.active)[0]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  let subtotal = 0;
  let universeMultiplierPrice = 0;
  let totalPrice = 0;
  let combinedDescription = "";
  const baseCombo = baseCombos.find(
    (bc) =>
      bc.active &&
      bc.project_type_id === selProjType?.id && 
      bc.controller_type_id === selControllerType?.id
  );
  if (baseCombo !== undefined) {
    const baseProjName = projectTypes.find((pt) => pt.id === baseCombo.project_type_id)?.name ?? "";
    const baseContName = controllerTypes.find((ct) => ct.id === baseCombo.controller_type_id)?.name ?? "";
    baseCombo.name = `${baseProjName} + ${baseContName}`;
    subtotal =
      baseCombo.price + outputs.reduce((accumulation, output) => accumulation + output.price, 0);
    universeMultiplierPrice = subtotal * (((uniMultiplier - 1) * PERCENT_PER_UNIVERSE) / 100);
    totalPrice = subtotal + universeMultiplierPrice;
    combinedDescription =
      baseCombo.description +
      "\n\n" +
      outputs.reduce(
        (accumulation, output) =>
          output.desc ? accumulation + output.desc + "\n\n" : accumulation,
        ""
      );
  }

  function onChange_selProjType(event: SelectChangeEvent<number>) {
    const ptId = event.target.value;
    const newValue = projectTypes.find((pt) => pt.id === ptId) ?? null;
    setSelProjType(newValue);
  }
  function onChange_selControllerType(event: SelectChangeEvent<number>) {
    const ctId = event.target.value;
    const newValue = controllerTypes.find((ct) => ct.id === ctId) ?? null;
    setSelControllerType(newValue);
  }
  function onChange_uniMultiplier(newValue: number) {
    newValue = newValue <= 0 ? 1 : newValue;
    setUniMultiplier(newValue);
  }
  function updateOutputs(index: number, extendedPrice: number, description: string) {
    const currPrice = outputs[index]?.price;
    const currDesc = outputs[index]?.desc;
    if (currPrice === extendedPrice && currDesc === description) return;
    setOutputs((prev) => {
      const newOutputs = [...prev];
      newOutputs[index] = { price: extendedPrice, desc: description };
      return newOutputs;
    });
  }
  const handleOnClickDesc = () => {
    navigator.clipboard.writeText(combinedDescription);
    setCopiedDesc(true);
  };
  const handleOnCloseCopyTooltip = () => {
    setCopiedDesc(false);
  };
  function handleConfigClick() {
    setConfigView(true);
  }
  function handleCancelConfig() {
    setConfigView(false);
  }

  return configView ? (
    <Config 
      cancelConfig={handleCancelConfig} 
      projectTypes={projectTypes}
      controllerTypes={controllerTypes}
      baseCombos={baseCombos}
      serviceComponents={serviceComponents}
      reloadData={reloadData}
    />
  ) : (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          minWidth="50%"
          display="flex"
        >
          <FormControl
            fullWidth
            size={"small"}
            sx={{ minWidth: "175px" }}
          >
            <InputLabel id="project-type-label">Project Type</InputLabel>
            <Select
              value={selProjType ? selProjType.id : ""}
              label={"Project Type"}
              labelId="project-type-label"
              onChange={onChange_selProjType}
            >
              {projectTypes?.filter(pt => pt.active).map((pt, i) => (
                <MenuItem
                  key={i}
                  value={pt.id}
                >
                  {pt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            size={"small"}
            sx={{ minWidth: "175px", marginLeft: "25px" }}
          >
            <InputLabel id="controller-type-label">Controller Type</InputLabel>
            <Select
              value={selControllerType ? selControllerType.id : ""}
              label="Controller Type"
              labelId="controller-type-label"
              onChange={onChange_selControllerType}
            >
              {controllerTypes?.filter(ct => ct.active).map((ct, i) => (
                <MenuItem
                  key={i}
                  value={ct.id}
                >
                  {ct.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <StyledNumberInput
            type="number"
            size="small"
            label="Est. Universes"
            variant="outlined"
            value={uniMultiplier}
            onChange={(e) => onChange_uniMultiplier(Number(e.target.value))}
            helperText={"+" + (uniMultiplier - 1) * PERCENT_PER_UNIVERSE + "% Total Price"}
            sx={{ minWidth: "150px", marginLeft: "25px" }}
          />
        </Box>
        {configGranted && (
          <Box sx={{ "& .MuiButton-root": { marginLeft: "15px" } }}>
            <Button
              variant="outlined"
              color="info"
              startIcon={<SettingsIcon />}
              tabIndex={1}
              onClick={handleConfigClick}
            >
              Config
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer sx={{ margin: "20px 0" }}>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <TableCell>
                <Typography>
                  <strong>Service</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <strong>Service Value</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <strong>Quantity</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <strong>Price</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <strong>Extended</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <strong>Description</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <BaseComboComponent baseCombo={baseCombo} />
            {selProjType && selControllerType &&
              serviceComponents.filter(sc => sc.active)?.map((sc, i) => (
                <ServiceComponent
                  key={i}
                  index={i}
                  serviceComponent={sc}
                  projectType={selProjType}
                  controllerType={selControllerType}
                  updateOutputs={updateOutputs}
                />
              ))
            }
            <TableRow>
              <TableCell>
                <Typography>Universe Multiplier</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography>{CurrencyFormatter(universeMultiplierPrice, true)}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow sx={{ background: colors.primary[400] }}>
              <TableCell>
                <Typography>
                  <strong>Total:</strong>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography>
                  <strong>{CurrencyFormatter(totalPrice, true)}</strong>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Tooltip
          title={copiedDesc ? "Copied!" : "Click to copy"}
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: {
              enter: 800,
              exit: 0,
            },
          }}
          onClose={handleOnCloseCopyTooltip}
        >
          <Typography
            onClick={handleOnClickDesc}
            whiteSpace="pre-line"
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: colors.blueAccent[500],
              },
            }}
          >
            {combinedDescription}
          </Typography>
        </Tooltip>
      </Box>
    </>
  );
};

export default ProgrammingDescPriceTool;
